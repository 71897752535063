//@import '@fmr-pr178318/fds-token';

@import 'node_modules/@fmr-pr178318/fds-tokens/dist/scss/sass-variables';

/* TODO: ITCSS the whole deal */
/* TODO: create more consistent naming of classes, some of the below just aren't good enough */

/* colors - belongs in a colors file */
$white: rgba( 256, 256, 256, 1 );
$off-white: rgba( 238, 238, 238, 1 );
$fidelity-green: rgba( 64, 136, 0, 1 );
$light-grey: rgba( 153, 153, 153, 1 );
$grey: rgba( 211, 211, 211, 0.4 );
$dark-grey: rgba( 85, 85, 85, 1 );
$black: rgba( 0, 0, 0, 1 );

$black-light-transparency: rgba( 0, 0, 0, 0.12 );
$black-dark-transparency: rgba( 0, 0, 0, 0.24 );

* {
  box-sizing: border-box;
}

body {
  /* include font references */
  background-color: $fds-neutral-50;
  font-size: 16px;                      /* create a baseline font-size */
  margin: 0;                          /* reset for automatic margin in some browsers */
  min-width: 20em;                      /* 320px / 16px = 20em; */

  font-family: ariel, sans-serif;

  .webfont-loaded & {
    font-family: 'Fidelity Sans', ariel, sans-serif;
  }
}

/* belongs in a fonts file */
.webfont-loaded {
.font {

  &--ultra-light {
    font-family: 'Fidelity Sans Ultra Light';
  }

  &--light {
    font-family: 'Fidelity Sans Light';
  }

  &--light-italic {
    font-family: 'Fidelity Sans Light Italic';
  }

  &--italic {
    font-family: 'Fidelity Sans Italic';
  }

  &--bold {
    font-family: 'Fidelity Sans Bold';
  }

  &--bold-italic {
    font-family: 'Fidelity Sans Bold Italic';
  }

  &--extra-bold {
    font-family: 'Fidelity Sans Extra Bold';
  }

  &--condensed {
    font-family: 'Fidelity Sans Condensed Medium';
  }

  &--demibold {
    font-family: 'Fidelity Sans Demibold';
  }
}

}

/* belongs in a header file */
h1, h2, h3 {
  margin: 0;
  font-weight: 600;
  color: $black;
}

h1 { font-size: 1.5em; }
h2 { font-size: 1.1em; }
h3 { font-size: 0.9em; }

.page-header {
  padding: 1.5em 2em;
  color: #fff;
  background-color: $fds-green-900;
    border-bottom: 1px solid $light-grey;
}

.main-section {
  background-color: $fds-neutral-0;
  color: $dark-grey;
  padding: 0 0.5em 1.5em;
  max-width: 62.5em;                        /* 1000px / 16px = 62.5em */

  @media screen and ( min-width: 42.5em ) {           /* 680px / 16px = 42.5em */
    margin: 2em;
    padding: 1.5em;
    border-color: $fds-neutral-200;                   /* 1px / 16px = 0.0625 */
    border-radius: $fds-radius-100;                              /* 3px / 16px = 0.1875 */
    border-width: $fds-stroke-100;
  }

  @media screen and ( min-width: 67.5em ) {           /* 1000px + 80px ( left and right margins ) / 16px = 67.5em */
    margin: 2em auto;
  }

  &--usage,
  &--examples {
    border-top: 1px solid $dark-grey;               /* 680px / 16px = 42.5em */

    @media screen and ( min-width: 42.5em ) {
      border-top: none;
    }
  }
}

.main-section__header-container {
  margin-bottom: 1em;
    text-align: center;
    border-bottom: 1px solid $dark-grey;
  padding: 1.5em 0 0.5em;

  @media screen and ( min-width: 42.5em ) {           /* 680px / 16px = 42.5em */
    padding-top: 0;
  }
}

.main-section__subheader {
  color: $light-grey;
    margin-top: 0.5em;
  font-size: 0.9em;
}

.body-section {
    margin: 2em 0 2em 1em;
  color: $dark-grey;
}

.body-section__subheader {
    margin: 2em 0 1.5em;
}

.body-subsection {
  margin: 1em 0 1em 1em;
}

.body-subsection__content {
    margin-left: 1.5em;
    font-size: 0.8em;
}

.body-section__path-header {
    margin-top: 1.5em;
}

/* example section */
.font-overview {
  display: flex;
  flex-wrap: wrap;
}

.font-overview__font-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: $grey;
  width: calc( 100% / 3 );
  padding: 1em 0.5em;

  @media ( min-width: 560px ) {
    width: calc( 100% / 6 );
  }
}

.font-overview__font-text {
    font-size: 3.75em;                        /* 60px / 16px = 3.75em */
}

.font-overview__font-name {
  height: 3em;
  text-align: center;
}

.font-example {
  font-size: 1.1em;
  padding: 0 0.5em;

  @media ( min-width: 21.875em ) {                /* 350px / 16px = 21.875em */
    font-size: 1.2em;
  }

  @media ( min-width: 25em ) {                  /* 400px / 16px = 25em */
    font-size: 1.4em;
  }

  @media ( min-width: 28.125em ) {                /* 450px / 16px = 28.125em */
    font-size: 1.4em;
  }

  @media ( min-width: 31.875em ) {                /* 510px / 16px = 31.875em */
    font-size: 1.8em;
  }

  @media ( min-width: 59.375em ) {                /* 950px / 16px = 59.375em */
    display: inline-block;
    width: 49%;
    font-size: 1.4em;
  }
}

.font-example__font-group {
  display: flex;
    flex-direction: column;
}

.font-example__row {

  line-height: 1.4em;

  &--uppercase {
    text-transform: uppercase;
  }
}